import React, { useState, useEffect } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

const MuiTimePicker = () => {
  const [value, setValue] = useState(null);
  const [presencePeriod, setPresencePeriod] = useState('');
  const [departureTime, setDepartureTime] = useState('');

  useEffect(() => {
    const calculateTimes = (checkInTime) => {
      if (!checkInTime) {
        setPresencePeriod('');
        setDepartureTime('');
        return;
      }

      let checkIn = new Date(checkInTime);

      // Adjust presence and departure times based on check-in time
      let presenceStart = new Date(checkIn.getTime() + 2 * 60 * 60 * 1000 + 1 * 60 * 1000);
      let presenceEnd = new Date(checkIn.getTime() + 3 * 60 * 60 * 1000);
      let departure = new Date(checkIn.getTime() + 6 * 60 * 60 * 1000 + 30 * 60 * 1000);

      const maxPresenceStartTime = new Date(checkIn);
      maxPresenceStartTime.setHours(12, 0, 0); // 12:00 PM
      if (presenceStart > maxPresenceStartTime) {
        presenceStart = maxPresenceStartTime;
      }

      const maxPresenceEndTime = new Date(checkIn);
      maxPresenceEndTime.setHours(12, 0, 0); // 12:00 PM
      if (presenceEnd > maxPresenceEndTime) {
        presenceEnd = maxPresenceEndTime;
      }

      const minPresenceEndTime = new Date(checkIn);
      minPresenceEndTime.setHours(9, 1, 0); // 09:01 AM
      if (presenceEnd < minPresenceEndTime) {
        presenceEnd = minPresenceEndTime;
      }

      const maxDepartureTime = new Date(checkIn);
      maxDepartureTime.setHours(15, 30, 0); // 3:30 PM
      if (departure > maxDepartureTime) {
        departure = maxDepartureTime;
      }

      setPresencePeriod(`بصمة التواجد: من ${formatTime(presenceStart)} الى ${formatTime(presenceEnd)}`);
      setDepartureTime(`بصمة الخروج: ${formatTime(departure)}`);
    };

    calculateTimes(value);
  }, [value]);

  const formatTime = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'م' : 'ص'; // Use Arabic for AM/PM
    hours = hours % 12 || 12;
    const strMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${strMinutes} ${ampm}`;
  };

  // Define the available AM time options (7:00 AM, 8:00 AM, 9:00 AM)
  const availableTimes = [
    dayjs().hour(7).minute(0),
    dayjs().hour(8).minute(0),
    dayjs().hour(9).minute(0),
  ];

  return (
    <div className="time-picker-container">
      <TimePicker
        label="بصمة الدخول"
        value={value}
        onChange={(newValue) => {
          if (newValue && newValue.hour() === 9) {
            newValue = newValue.minute(0); // Force minutes to 00
          }
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
        shouldDisableTime={(timeValue, viewType) => {
          const hour = timeValue.hour();
          if (hour === 9 && viewType === 'minutes') {
            return timeValue.minute() !== 0; // Disable all minutes except 00
          }
          return hour >= 12 || !availableTimes.some((time) => time.hour() === hour);
        }}
        ampm={false} // Hide AM/PM toggle
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
      />
      <div className="result">
        <div id="presencePeriod">{presencePeriod}</div>
        <div id="departureTime">{departureTime}</div>
      </div>
    </div>
  );
};

export default MuiTimePicker;
